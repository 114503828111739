import React, { useRef, useEffect, useState } from 'react';
import { string, bool, number, shape } from 'prop-types';
import classnames from 'classnames';
import ActionModal from '../action-modal';
import ActionRow from '../action-row';
import getDisplayName from '../../lib/get-display-name';

const namespace = 'ui-pdp-see-more';
const GAP = 10;
const MARGIN_BOX = 24;

/** @TODO
 * Make usable for desktop -deviceType=mobile asumes that the see more action is only for mobile
 * */

const withSeeMore = WrappedComponent => {
  const SeeMoreComponent = ({
    className,
    collapsedProps,
    expandedProps,
    action,
    modalTitle,
    modalClassName,
    modalHash,
    hasPreview,
    previewHeight,
    useCanSeeEverything,
  }) => {
    const ref = useRef();
    const [expandedHeight, setExpandedHeight] = useState(false);

    useEffect(() => {
      if (!expandedHeight && ref.current?.offsetHeight) {
        setExpandedHeight(ref.current.offsetHeight - MARGIN_BOX - GAP);
      }
    }, [expandedHeight]);

    const canSeeEverything = expandedHeight && previewHeight >= expandedHeight;
    const modalLocation = modalHash ? `#${modalHash}` : `#${action.target}`;
    const showAction = !useCanSeeEverything || !canSeeEverything;
    const collapsedClassNames = `${namespace}__content`;
    const cantSeeEverythingClassNames = {
      [`${namespace}--cant-see-everything`]: useCanSeeEverything && !canSeeEverything,
    };
    const previewClassNames = classnames(`${namespace}__container`, cantSeeEverythingClassNames);
    const previewStyle = !previewHeight ? {} : { maxHeight: `${previewHeight}px` };

    return (
      <div className={classnames(`${namespace}`, className)}>
        {hasPreview ? (
          <div className={previewClassNames} style={previewStyle}>
            <div ref={ref}>
              <WrappedComponent {...collapsedProps} className={collapsedClassNames} />
            </div>
          </div>
        ) : null}
        {action && showAction && (
          <ActionModal
            className={`${namespace}__action`}
            deviceType="mobile"
            componentAsLabel={<ActionRow label={action.label.text} modifier="secondary" />}
            modalClassName={classnames(`${namespace}__modal`, modalClassName)}
            modalTitle={modalTitle}
            url={action.target}
            modalUrl={modalLocation}
            track={action.track}
            closeModalLabel={action.close_modal_label}
          >
            <WrappedComponent {...expandedProps} />
          </ActionModal>
        )}
      </div>
    );
  };

  SeeMoreComponent.displayName = `SeeMore(${getDisplayName(WrappedComponent)})`;
  SeeMoreComponent.propTypes = {
    className: string,
    collapsedProps: shape({}),
    expandedProps: shape({}),
    action: shape({
      label: shape({
        text: string,
        color: string,
      }),
      target: string,
    }).isRequired,
    modalTitle: string,
    modalClassName: string,
    modalHash: string,
    hasPreview: bool,
    previewHeight: number,
    useCanSeeEverything: bool,
  };
  SeeMoreComponent.defaultProps = {
    className: null,
    collapsedProps: {},
    expandedProps: {},
    modalClassName: '',
    hasPreview: true,
    useCanSeeEverything: false,
  };

  return SeeMoreComponent;
};

export default withSeeMore;
