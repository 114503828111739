import React from 'react';
import { shape, bool, string, arrayOf } from 'prop-types';
import withSeeMore from '../../../action-see-more';
import HSStripedSpecs from '../hs-striped-specs';

const namespace = 'ui-vpp-highlighted-specs__see-more';
const HEIGHT_BOX = 400;

const SpecsWithSeeMore = withSeeMore(HSStripedSpecs);

const HSActionRow = ({ action, specs, title, existAttributes }) => (
  <SpecsWithSeeMore
    className={namespace}
    collapsedProps={{ specs }}
    expandedProps={{ specs }}
    action={action}
    modalTitle={title}
    modalClassName={`${namespace}__modal`}
    modalHash="highlighted-specs"
    hasPreview={!existAttributes}
    previewHeight={HEIGHT_BOX}
    useCanSeeEverything
  />
);

HSActionRow.propTypes = {
  action: shape({
    label: shape({
      text: string,
      color: string,
    }),
    target: string,
  }).isRequired,
  specs: arrayOf(shape({})).isRequired,
  title: string.isRequired,
  existAttributes: bool,
};

export default React.memo(HSActionRow);
