import React from 'react';
import { shape, arrayOf } from 'prop-types';
import HSKeyValue from '../hs-key-value/hs-key-value';

const namespace = 'ui-vpp-highlighted-specs__key-value-carousel';

const HSKeyValueCarousel = ({ list_key_value }) => (
  <div className={namespace}>
    <div className={`${namespace}__container`}>
      <div className={`${namespace}__list`}>
        {list_key_value &&
          list_key_value.map((keyValue, i) => {
            if (keyValue.state === 'HIDDEN') {
              return null;
            }
            const keyAttribute = `${keyValue.id || keyValue.type}-${i}`;
            return <HSKeyValue key={keyAttribute} {...keyValue} />;
          })}
      </div>
    </div>
  </div>
);

HSKeyValueCarousel.propTypes = {
  list_key_value: arrayOf(shape(HSKeyValue.propTypes)),
};

HSKeyValueCarousel.defaultProps = {
  list_key_value: null,
};

export default HSKeyValueCarousel;
