import { loadable } from 'nordic/lazy';
import React, { useMemo } from 'react';
import { arrayOf, shape, func, string } from 'prop-types';

// Basics
import ComponentList from '../component-list';

import withMappedProps from '../../utils/withMappedProps';
import classnames from 'classnames';

// Highlighted Specs Components
import StyledLabel from '../styled-label';

import labelToProps from '../styled-label/label-to-props';
import compatsToProps from '../compats-widget/block/compats-to-props';
import HSHighlightedAttributes from './components/hs-highlighted-attributes';
import HSHighlightedAttributesList from './components/hs-highlighted-attributes-list';
import HSDiscreteBar from './components/hs-discrete-bar';
import HSContinousBar from './components/hs-continous-bar';
import HSKeyValue from './components/hs-key-value';
import HSKeyValueCarousel from './components/hs-key-value-carousel';
import HSActionRow from './components/hs-action-row';
import useTrackView from '../../hooks/use-track-view';

const CompatsWidget = loadable(() => import('../compats-widget/block'));

const namespace = 'ui-vpp-highlighted-specs';

const availableComponents = {};
availableComponents.label_component = withMappedProps(StyledLabel)(labelToProps);
availableComponents.compats_widget_block = withMappedProps(CompatsWidget)(compatsToProps);
availableComponents.composed_highlight = withMappedProps(CompatsWidget)(compatsToProps);
availableComponents.highlighted_attributes = HSHighlightedAttributes;
availableComponents.highlighted_attributes_list = HSHighlightedAttributesList;
availableComponents.discrete_bar = HSDiscreteBar;
availableComponents.continuous_bar = HSContinousBar;
availableComponents.key_value = HSKeyValue;
availableComponents.key_value_carousel = HSKeyValueCarousel;
availableComponents.technical_specifications = HSActionRow;

const HIGHLIGHTED_NEW_EXPERIENCE = 'highlighted_specs_attrs_new';

const useInitialState = (components, getDispatch, feature_name) =>
  useMemo(
    () => ({
      components: components?.map((c, _, list) => {
        if (c.id === 'technical_specifications') {
          return {
            ...c,
            existAttributes:
              list.filter(
                ({ type }) => type === 'continuous_bar' || type === 'key_value' || type === 'key_value_carousel',
              )?.length > 0,
            getDispatch,
            feature_name,
          };
        }
        return { ...c, getDispatch, feature_name };
      }),
    }),
    [components, getDispatch, feature_name],
  );

const HighlightedSpecsAttrs = ({ components, viewport_track, getDispatch, feature_name, runCatchErrorBoundary }) => {
  try {
    /* eslint-disable react-hooks/rules-of-hooks */
    const initialState = useInitialState(components, getDispatch, feature_name);
    const viewRef = useTrackView(viewport_track, { threshold: 0.45 });

    return (
      <section
        ref={viewRef}
        className={classnames(namespace, {
          'ui-vpp-highlighted-specs-rex': feature_name === HIGHLIGHTED_NEW_EXPERIENCE,
        })}
      >
        <ComponentList availableComponents={availableComponents} initialState={initialState} />
      </section>
    );
    /* eslint-enable react-hooks/rules-of-hooks */
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

HighlightedSpecsAttrs.propTypes = {
  components: arrayOf(shape()).isRequired,
  viewport_track: shape({}),
  getDispatch: func,
  feature_name: string,
  runCatchErrorBoundary: func,
};

HighlightedSpecsAttrs.defaultProps = {
  viewport_track: null,
  runCatchErrorBoundary: () => {},
};

export default React.memo(HighlightedSpecsAttrs);
